import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      <div>
        Bora Erden is a spatial researcher based in New York, USA.{" "}
      </div>
      <div>
        At the{" "}
        <a href="https://www.nytimes.com/by/bora-erden" target="_blank" rel="noreferrer">
          New York Times
        </a>
        , he applies geospatial, computational, and investigative techniques on the Graphics desk. Previously at{" "}
        <a href="https://situ.nyc/research" target="_blank" rel="noreferrer">
          SITU Research
        </a>
        , he provided forensic evidence to national and international judicial, advocacy, journalistic and activist organizations.

        His work has been commissioned by Amnesty International, Human Rights Watch, the International Criminal Court, United Nations mechanisms, 
        the Associated Press, and the Equipo Argentino de Antropología Forense, among others.
      </div>
      <div>
        More broadly, his interests lie in finding liberatory applications for algorithmic technologies 
        through the strategic use of counter-forensic analysis.
      </div>
    </Page>
  )
}
